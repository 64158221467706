import { Entity } from '@backstage/catalog-model';
import { JsonObject } from '@backstage/types';

const hasPartRelation = (entity: Entity) =>
  Boolean(
    entity?.relations?.some(
      (relation) => relation.type === 'hasPart' && (relation as any)?.target?.kind === 'component'
    )
  );

export const isCostEnable = (entity: Entity) =>
  !entity.metadata?.tags?.includes('apigee') &&
  hasPartRelation(entity) &&
  (entity.metadata?.alquimia as JsonObject)?.templateType !== 'api-gateway';
