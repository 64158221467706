import React from 'react';

import { Alert, Grid, Link } from '@mui/material';

import { EntityWarningContent } from '../EntityWarning';
import { AboutCard } from '../AboutCard';

const SimpleOverviewContent = (
  <Grid container alignItems="stretch" spacing={2}>
    <EntityWarningContent/>
    <Grid item md={12}>
    <Alert severity="warning">
      Type do componente não reconhecido. Consulte esta <Link href="/docs/default/component/alquimia/componentes-alquimia/catalog/yaml-format/#spectype-required" target="_blank" rel="noopener">
        documentação
      </Link>  para verificar os tipos disponíveis e ajuste o campo <code>`type`</code> no arquivo <code>`catalog-info.yaml`</code> do seu componente para ter uma melhor experiência.
    </Alert>
    </Grid>
    <Grid item md={12}>
      <AboutCard />
    </Grid>
  </Grid>
);

export { SimpleOverviewContent };
