import React from 'react';

import { useEntity } from '@backstage/plugin-catalog-react';

import * as T from './types';
import components from './ComponentLayout/components';
import { EntityLayout } from '@backstage/plugin-catalog';
import { usePermission } from '@backstage/plugin-permission-react';
import permission from './ComponentLayout/permission';
import { isArgocdAvailable } from '@grupoboticario/plugin-argo-cd-frontend';
import { Entity } from '@backstage/catalog-model';

export const ComponentPage = () => {
  const { entity } = useEntity();
  const type = (entity?.spec?.type as T.EntityType) || 'others';

  const inputPermission = {
    permission: permission,
    resourceRef: `component:${entity.metadata.namespace}/${entity.metadata.name}`
  };
  const { allowed } = usePermission(inputPermission);

  return (
    <EntityLayout>
      {components.map(({ path, title, content: Component, componentType, showContent }) => {
        const customValidation = (entity: Entity) => {
          const showComponentByType = showContent(type, allowed);
          const showOnlyArgocd = componentType === 'argocd' && isArgocdAvailable(entity);

          if (showComponentByType && showOnlyArgocd) {
            return true;
          }
          return showComponentByType;
        };

        return (
          <EntityLayout.Route
            path={path}
            title={title}
            key={title}
            if={(entity: Entity) => customValidation(entity)}
          >
            {Component}
          </EntityLayout.Route>
        );
      })}
    </EntityLayout>
  );
};
